$( document ).ready(function() {
// Like button
    $( "#likeButtonOpen" ).click(function() {
        $( "#like_butt_hold" ).toggle('fast');
    });
});


// MODAL LOAD MANAGEMENT PANORAMA
$('#myModal').on('hidden.bs.modal', function () {
    $(this).removeData('bs.modal');
});
// MODAL LOAD MANAGEMENT PANORAMA


$(document).ready(function(){
    // handle the mouseenter functionality
    $(".img").mouseenter(function(){
            $(this).addClass("hover");
        })
        // handle the mouseleave functionality
        .mouseleave(function(){
            $(this).removeClass("hover");
        });
});


// PLUS SIGN, MINUS SIGN MOBILE MENU DROPDOWN
jQuery(document).ready(function($){
    //open/close primary navigation
    $('a#toggleBtn').on('click', function(){
        $(this).next("ul").toggle('fast', function() {
            $(this).toggleClass('closed opened');
        });//.toggleClass('closed opened');
        $(this).toggleClass('collapse-plus collapse-minus');
    });

});

//Menu Hamburger from bars to close icon
$(document).ready(function(){
    $('.lines-button').click(function(){
        $(this).toggleClass('close2');
    });
});


<!-- SIMULATE FIXED MENU ONLY HOMEPAGE-->
jQuery(document).ready(function($){
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll > 90) {
            $(".fixedMenuLargeBanerRotator").removeClass("noFixedMenu");
        } else {
            $(".fixedMenuLargeBanerRotator").addClass("noFixedMenu");
        }
    });
});


