
jQuery(document).ready(function() {

    var sliderindex = jQuery("#slider1-index").revolution({
        sliderType:"standard",
        sliderLayout:"fullscreen",
        extensions: '/website/js/revolutionslider5/extensions/',
        delay:5000,
        disableProgressBar:"on",
        lazyType: "all",
        navigation: {
            onHoverStop:"off",
            keyboardNavigation:"off",
            touch:{
                touchenabled: "on",
                swipe_treshold:  75,
                swipe_min_touches : 1,
                drag_block_vertical: false,
                swipe_direction: "horizontal"
            },
            arrows: {
                style:"",
                enable:true,
                hide_onmobile:true,
                hide_onleave:true,
                hide_delay:200,
                hide_delay_mobile:1200,
                hide_under:0,
                hide_over:9999,
                tmp:'',
                left : {
                    h_align:"left",
                    v_align:"center",
                    h_offset:20,
                    v_offset:0
                },
                right : {
                    h_align:"right",
                    v_align:"center",
                    h_offset:20,
                    v_offset:0
                }
            }
        },
        //  fullScreenAlignForce:"on", //align caption works now //deprecated v.5.2
        fullScreenOffsetContainer: "#menu-mobile"
    });

    // Index Banner Rotator INNER Settings
    var sliderinner = jQuery("#slider1-inner").revolution({
        sliderType: "standard",
        sliderLayout: "fullwidth",
        extensions: '/website/js/revolutionslider5/extensions/',
        delay: 5000,
        disableProgressBar: "on",
        responsiveLevels: [4064, 1024, 778, 480],
        minHeight: [550, 460, 420, 300],
        gridwidth: [1230, 1230, 800, 400],
        gridheight: [550, 460, 420, 300]

        //  fullScreenAlignForce:"on", //align caption works now //deprecated v.5.2
        // fullScreenOffsetContainer: "#offset-div"
    });


    /* OPRAVEN BUG S PODSKACHANETO NA SLIDERA (ISSUE #1), vij i slider.scss */
    // event for when slider has been loaded
    sliderindex.on('revolution.slide.onloaded', function() {
        $("section#slider").css("min-height", "0");
        // get current slide / max slides jQuery('#sliderid').revcurrentslide();
        maxslides =  $("section#slider").revmaxslide();
        $("span.allslides").empty().append( maxslides );
    });
    // event for when slider has been loaded
    sliderinner.on('revolution.slide.onloaded', function() {
        $("section#slider").css("min-height", "0");
    });



});



// Slick Slider Settings
$(document).ready(function(){
    $('.slickslider-kareta2').slick({
        autoplay: false,
        variableWidth: false,
        dots: false,
        autoplaySpeed: 2000, //default
        easing: 'linear', //linear default no need other script for linear
        //lazyLoad: 'ondemand', //activate lazy loading, img data-lazy vmesto src="", raboti pravilno samo, ako infinite: false!!!
        prevArrow: $('.slick3-prev'),
        nextArrow: $('.slick3-next'),
        appendArrows: $('.slick3-arrows-container'),
        infinite: false,
        useCSS: false,  /// opravq preskachaneto na layera
        speed: 150,
        rows: 1,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    arrows: false,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
});






 